  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300,400;500;600;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
  }

  :root {
    --primaryFont: 'Inter', sans-serif;
    --rootTextColorDark: #DEDFE3;
    --rootTextColorLight: #9CA3AF;
    --rootBgColor: #171717;
    --textGradient: linear-gradient(to right, #84F6C3 0%, #51A6FA 84%);
    --lightButton: #222222;
    --boxShadow: #1e1e1eac;
    --buttonColor: #005860;
    --navBg: #252424;
    --navBoxShadow: #181717;
    --dashColor: #ffffff14;
  }

  body {
    background-color: var(--rootBgColor);
    color: var(--rootTextColorDark);
    font-family: var(--primaryFont);
    position: relative;
    overflow-x: hidden;
  }

  .container {
    width: 80vw;
    margin: auto;
  }

  .gradient {
    width: fit-content;
    background: var(--textGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .dash {
    height: 2px;
    flex: 1;
    background-color: var(--dashColor);
    margin-left: 5px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 25px 0;
  }

  .flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .align-items-center {
    align-items: center;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .small-flex-wrap {
    flex-wrap: nowrap;
  }

  a {
    text-decoration: none;
  }

  .relative {
    position: relative;
  }

  .w-100 {
    width: 100%;
  }

  .ml-50 {
    margin-left: 50px;
  }



  .mr-50 {
    margin-right: 50px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .m-0 {
    margin: 0;
  }

  .svg-25 {
    width: 25px;
  }

  .pointer {
    cursor: pointer;
  }

  .svg-color {
    stroke: var(--buttonColor);
  }

  /* Header */

  header {
    padding: 20px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 20;
    background-color: var(--rootBgColor);
  }

  .small_screen {
    display: none;
    position: relative;
  }

  header.active {
    background-color: var(--navBg);
    opacity: 0.95;
    box-shadow: 0 5px 25px var(--navBoxShadow);
  }

  .header_logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #22af6a;
  }

  .header_logo_name {
    margin-left: 10px;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.8;
    color: var(--rootTextColorLight);
    margin-bottom: 0;
  }

  nav ul {
    list-style: none;
    display: flex;
  }



  nav ul li {
    padding: 10px 20px;
  }

  nav ul li a {
    color: var(--rootTextColorLight);
    font-weight: 500;
    font-size: 15px;
  }

  nav ul li:hover {
    background-color: #9f9f9f5f;
    border-radius: 5px;
    cursor: pointer;
  }

  .small_screen ul {
    position: absolute;
    display: none;
    background: #171717;
    top: 50px;
    right: 0;
    border: 2px solid var(--lightButton);
  }

  .small_screen ul.active {
    display: block;
  }

  .small_screen button {
    border: none;
    background: transparent;
  }

  .rootTextSvg {
    fill: var(--rootTextColorDark);
    stroke: var(--rootTextColorDark);
  }

  /* home */

  #home {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .animate-pulse1 {
    position: absolute;
    top: 50px;
    left: 0;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite alternate;
  }

  .animate-pulse2 {
    position: absolute;
    top: 50px;
    right: 0;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite alternate;

  }

  @keyframes pulse {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1;
    }
  }

  .dogModel {
    width: 500px;
    height: 500px;
    margin-top: 70px;
    position: relative;
  }

  #home .name {
    font-size: 50px;
    margin-bottom: 5px;
    display: inline;
  }

  #home .greet {
    letter-spacing: 1px;
    color: var(--rootTextColorLight);
  }

  #home .designation {
    margin: 15px 0 20px;
    color: var(--rootTextColorLight);
    font-size: 18px;
    font-weight: 600;
    opacity: 0.7;
  }

  #home button {
    padding: 10px 20px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    border-radius: 3px;
  }

  #home button:hover {
    box-shadow: 0px -1px 31px -3px #276873;
  }

  #home .more_about {
    background-color: var(--buttonColor);
    color: white;
  }

  #home .connect {
    margin-left: 20px;
    background-color: var(--lightButton);
    box-shadow: 0 5px 25px var(--boxShadow);
    color: var(--rootTextColorDark);
  }

  /* about */

  .about_profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }

  .about_para {
    line-height: 1.5;
    margin-bottom: 10px;
    color: var(--rootTextColorLight);
  }


  .profile {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 1px solid #276873;
  }

  .profile img {
    -webkit-filter: grayscale(1);
    /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all 0.8s ease-in-out;
  }

  .resume {
    padding: 10px 20px;
    background-color: var(--buttonColor);
    color: white;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 15px;
  }

  .resume:hover {
    box-shadow: 0px -1px 31px -3px #276873;
  }

  /* tech */

  #tech {
    margin: 30px 0;
  }

  #tech button {
    display: flex;
    padding: 12px 15px 4px;
    border-radius: 5px;
    background-color: var(--lightButton);
    border: none;
    box-shadow: 0 5px 25px var(--boxShadow);
    width: 205px;
    margin: 0 20px 20px 0;
  }

  #tech button img {
    width: 30px;
  }

  #tech button p {
    font-size: 17px;
    font-weight: 600;
    margin-left: 12px;
    color: var(--rootTextColorLight);
    text-align: left;
  }

  /* project */

  #projects {
    margin-top: 40px;
  }

  video {
    width: 500px;
    border-radius: 10px;
  }

  .project_name {
    margin-bottom: 15px;
    font-size: 22px;
    color: var(--rootTextColorDark);
  }

  .project_name span {
    font-size: 16px;
    color: var(--rootTextColorLight);
  }

  .project {
    display: flex;
    color: var(--rootTextColorLight);
    line-height: 1.4;
    margin-bottom: 50px;
  }

  .project_desc {
    margin-left: 20px;
  }

  .project_techs {
    margin-top: 15px;
  }

  .project_techs strong {
    color: var(--rootTextColorDark);
  }

  .project_demo a {
    margin-right: 15px;
    color: #2283CD;
    display: flex;
    align-items: center;
  }

  .project_demo a span {
    margin-left: 5px;
  }

  /* animation  */

  .wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
    font-size: 30px;
    margin-left: 5px;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(14deg);
    }

    20% {
      transform: rotate(-8deg);
    }

    30% {
      transform: rotate(14deg);
    }

    40% {
      transform: rotate(-4deg);
    }

    50% {
      transform: rotate(10deg);
    }

    60% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  /* experience */

  .company_name {
    margin-bottom: 20px;
  }

  .company_name img {
    border-radius: 100px;
  }

  .company_name p {
    color: var(--rootTextColorLight);
    margin-top: 5px;
  }

  .company_name span {
    font-size: 18px;
    margin-left: 5px;
    /* word-break: break-all */
  }

  .projects_worked {
    font-size: 18px;
    text-decoration: underline;
  }

  .company_projects {
    margin-left: 20px;
  }

  .company_project {
    margin: 20px 0;
  }

  .company_project_desc,
  .company_project_tech {
    color: var(--rootTextColorLight);
    margin-top: 15px;
  }

  .company_project_tech span {
    color: var(--rootTextColorDark);
  }

  /* contact */

  #contact {
    margin: 30px 0;
  }

  .contact_info {
    width: 500px;
    margin: 20px 20px 0 0;
  }

  .contact_para {
    color: var(--rootTextColorLight);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .contact_svg {
    padding: 7px;
    background-color: #bebbbb;
    border-radius: 50%;
    margin-right: 8px;
  }



  .contact_detail {
    color: var(--rootTextColorLight);
    font-size: 15px;
    margin-bottom: 0;
  }

  .contact_inputs {
    flex: 1;
    /* min-width: 500px; */
  }

  .alert {
    margin-top: 10px;
    color: #2283CD;
  }

  .alert {
    margin-top: 10px;
    color: #2283CD;
  }


  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform(-50%, -50%);
    border: 5px solid var(--rootTextColorLight);
    border-radius: 50%;
    border-top: 7px solid var(--buttonColor);
    animation: spin 1s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* input */

  .input_combine {
    display: flex;
  }

  .input_combine .form__group {
    flex: 1;
    margin-right: 15px;
  }

  .form__group {
    position: relative;
    padding: 15px 0 0;
    margin: 10px 0 20px;
  }

  .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 15px;
    color: var(--rootTextColorDark);
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }

  .form__field::placeholder {
    color: transparent;
  }

  .form__field:placeholder-shown~.form__label {
    font-size: 15px;
    cursor: text;
    top: 20px;
  }

  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
  }

  .form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #3B82F6, #38ef7d);
    border-image-slice: 1;
  }

  .form__field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #3B82F6;
    font-weight: 700;
  }

  .submit {
    padding: 10px 20px;
    background-color: var(--buttonColor);
    color: white;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 15px;
    border: none;
    position: relative;
  }

  .tech-sub-head {
    font-size: 24px;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
  }

  @media screen and (max-width: 1200px) {
    .small-flex-wrap {
      flex-wrap: wrap;
    }

    .project_desc {
      margin: 10px 0 0 0;
    }
  }


  @media screen and (max-width: 900px) {
    .container {
      width: 90vw;
    }

    #header {
      width: unset;
    }

    .small_screen {
      display: block;
    }

    .large_screen {
      display: none;
    }

    #home {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .dogModel {
      width: 400px;
      height: 400px;
    }

    .home_content {
      margin-top: -50px;
      z-index: 2;
    }

    video {
      width: 100%;
    }


  }

  @media screen and (max-width: 550px) {
    .dogModel {
      width: 300px;
      height: 300px;
    }

    .animate-pulse2 {
      display: none;
    }

    .animate-pulse1 {
      width: 100%;
    }

    #home .name {
      font-size: 30px;
    }

    .home_content {
      align-self: flex-start;
    }

    .titleContainer {
      width: 100%;
    }

    #tech button {
      margin: 0 10px 10px 0;
      width: fit-content;
    }

  }